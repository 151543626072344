:root { 
  --color0-green: #95a844;
  --color1-darkgrey: #44494f;
  --color2-darkgreengray: #8f997a;
  --color4-lightGreen: #dedabb;
}

body {
  margin: 0;
  font-family: "Signika", sans-serif;
  font-size:16px;
	color: var(--color1-darkgrey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: "Signika", sans-serif;
  color: var(--color2-darkgreengray);
}

h2,h3,h4 {
  font-family: "Signika", sans-serif;
  color: var(--color1-darkgrey);
}